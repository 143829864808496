<template>
  <BaseDialog :model-value="modal.status" size-class="!max-w-2xl" @update:model-value="close">
    <template #header>
      {{ folderId ? 'Update Folder' : 'Create Folder' }}
    </template>
    <template #default>
      <div class="flex-col items-center space-y-2 py-4">
        <div class="text-sm font-medium text-gray-100">Folder Name</div>
        <BaseTextbox placeholder="Enter Folder Name" v-model="folderPayload.name" maxlength="30" />
        <span v-if="folderNameError" class="text-xs text-red-400">{{ folderNameError }}</span>
      </div>
      <div class="flex flex-col space-y-2" v-if="!teamWatchlistIsSelected">
        <p class="text-sm">Folder Actions</p>
        <BaseCheckbox
          v-if="!(folderPayload.selectedTeamWatchlist && typeof folderPayload.selectedTeamWatchlist.id == 'number')"
          v-model="folderPayload.shareWatchlist"
        >
          Share folder with team
        </BaseCheckbox>
        <span
          v-if="folderPayload.shareWatchlist"
          :class="[teamWatchlistIsSelected ? 'pointer-events-none' : '', 'flex flex-col gap-2 text-sm text-gray-400']"
        >
          Maintainers (optional)
          <BaseSelect
            v-model="folderPayload.maintainers"
            :searchable="true"
            :options="maintainersOptions"
            :multiple="true"
            :display-count="1"
            :search-options="['label', 'email']"
            placeholder="Select Maintainer(s)"
            position="bottom"
            button-width-class="w-80"
            dropdown-width-class="w-80"
            portal
          />
        </span>
      </div>
    </template>
    <template #footer>
      <div class="flex w-full space-x-2 px-2" :class="folderId ? 'justify-between' : 'justify-end'">
        <BaseButton type="danger" v-if="folderId" @press="deleteFolder()"> Delete </BaseButton>
        <div class="flex items-center space-x-2">
          <BaseButton :disabled="buttonBusy" type="secondary" @press="close"> Cancel </BaseButton>
          <BaseButton :busy="buttonBusy" :disabled="buttonBusy" @press="createUserFolder()">{{
            folderId ? 'Update' : 'Create'
          }}</BaseButton>
        </div>
      </div>
    </template>
  </BaseDialog>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, computed, watch } from 'vue';
import { useWatchlistStore } from '@/stores/watchlists';

const $store = useStore();
const $watchlistStore = useWatchlistStore();
const $emit = defineEmits(['close', 'set-folder']);

//MODAL
const modal = computed(() => {
  return $store.getters.onchainFolderModal;
});
const close = () => {
  $store.commit('setOnchainFolderModal', {
    status: false,
    payload: {}
  });
  folderPayload.value = {
    name: '',
    selectedWatchlist: {},
    selectedTeamWatchlist: {},
    shareWatchlist: false,
    maintainers: [],
    watchlist_type: 'onchain_addresses'
  };
  buttonBusy.value = false;
  folderNameError.value = '';
};

//MANAGE FOLDER
const oldMaintainer = ref([]);
const folderNameError = ref('');
const folderPayload = ref({
  name: '',
  selectedWatchlist: {},
  selectedTeamWatchlist: {},
  shareWatchlist: false,
  maintainers: [],
  watchlist_type: 'onchain_addresses'
});
const selectedFolder = ref(null);
const buttonBusy = ref(false);
const folders = computed(() => $watchlistStore.onchainFolders);
const folderId = computed(() => {
  return modal.value.payload?.id;
});
const teamWatchlistIsSelected = computed(() => {
  return folderPayload.value.selectedTeamWatchlist && folderPayload.value.selectedTeamWatchlist.id > 0;
});
async function deleteFolder() {
  await $watchlistStore.openWatchlistDeleteModal(selectedFolder.value.id);
  close();
}
async function createUserFolder() {
  let existingFolder = folders.value?.find(x => x.name.toLowerCase() == folderPayload.value?.name.toLowerCase());
  if (folderPayload.value?.name.trim().length == 0) {
    folderNameError.value = 'Please enter folder name.';
  } else if (existingFolder && folderId.value != existingFolder.id) {
    folderNameError.value = 'Folder with this name already exists.';
  } else {
    buttonBusy.value = true;
    if (folderId.value) {
      let payloadData = {
        ...folderPayload.value,
        selectedWatchlist: { id: selectedFolder.value.id },
        name: folderPayload.value?.name,
        watchlist_type: 'onchain_addresses'
      };
      await $watchlistStore.updateWatchlist(payloadData, { maintainers: oldMaintainer.value.map(m => m.id) });
    } else {
      await $watchlistStore.createWatchlist({
        ...folderPayload.value,
        name: folderPayload.value?.name,
        watchlist_type: 'onchain_addresses'
      });
    }
    buttonBusy.value = false;
    close();
  }
}
watch(folderId, async () => {
  if (folderId.value) {
    selectedFolder.value = folders.value?.find(x => x.id == folderId.value);
    folderPayload.value.name = selectedFolder.value.name;

    oldMaintainer.value = selectedFolder.value.maintainers;

    folderPayload.value = {
      ...selectedFolder.value,
      selectedTeamWatchlist: {},
      selectedCategory: {},
      shareWatchlist: selectedFolder.value.team_entity_permissions,
      maintainers: oldMaintainer.value.map(m => m.id),
      alreadySubscribed: false
    };
  }
});

//TEAMMATES
const teammates = ref([]);
const maintainersOptions = computed(() => {
  return teammates.value.map(user => {
    return {
      ...user,
      sub_label: user.first_name || user.last_name ? user.email : ''
    };
  });
});
watch(modal, async (newVal, oldVal) => {
  if (newVal.status) {
    teammates.value = await $watchlistStore.getTeammates();
  }
});
</script>
